/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2024/nablv/nab-show-desktop-hero.jpg';
import mobileBanner from '../../images/events/2024/nablv/nab-show-mobile-hero.jpg';
import ogimage from '../../images/events/2024/nablv/nab-show-og-image.jpg';

export default function NABNY2024() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='NAB Show New York 2024 - Witbe' isEvents>
            <SEO
                title='Meet Witbe‘s team at NAB Show in Las Vegas'
                ogDescription='Visit Witbe at Booth W2053 in Las Vegas at the NAB Show 2024! See a live demo of our Ad Monitoring and Matching technology for video service providers.'
                description='Discover how you can improve your QoE with our video testing and monitoring technology, now faster with more AI.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={10} banner={mobileBanner} /> : <DesktopEventPage id={10} banner={banner} />}
        </Layout>
    )
}
